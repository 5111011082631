import { ref, type Ref, type UnwrapRef } from 'vue'
import { deepClone } from './clone'

export type FormErrors<T> = {
  [K in keyof T]: string[]
} & {
  [key: string]: string[]
}

export interface Form<T> {
  form: Ref<UnwrapRef<T>>
  errors: Map<string, Partial<FormErrors<T>>>
  setErrors: (value: unknown) => void
  clearErrors: () => void
  clearForm: () => void
  reset: () => void
}

export function errorHandler(error: any) {
  const { message } = error.response?.data ?? error

  if (error) {
    const { errors } = error.response!.data

    if (errors) {
      return { errors }
    } else {
      console.warn(message)
      return { errors }
    }
  } else if (error instanceof Error) {
    console.warn(message)
    return { errors: error }
  } else {
    throw error
  }
}

export function useForm<T>(values: T): Form<T> {
  const initialValues = ref(deepClone(values))

  const form = ref<T>(values)
  const errors = new Map<string, Partial<FormErrors<T>>>()

  function setErrors(valeus: unknown) {
    const { errors } = errorHandler(valeus)

    for (const key in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, key)) {
        errors.set(key, errors)
      }
    }
  }

  function clearForm() {
    form.value = deepClone(initialValues.value)
  }

  function clearErrors() {
    errors.clear()
  }

  function reset() {
    clearForm()
    clearErrors()
  }

  return {
    form,
    errors,
    setErrors,
    clearErrors,
    clearForm,
    reset
  }
}
