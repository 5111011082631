import { api } from '@/services/api'

import { reactive, shallowRef, type Ref } from 'vue'

import type { CanvasFilter, RulesSet } from '=/types'
import { useForm } from '@/services/form'
import { useToast } from '@/components/toast'

export interface Canvas {
  image: string
  height: number
  width: number
}

const { showToast, updateToast, removeToast } = useToast()

const controller = reactive({
  render: new AbortController(),
  options: new AbortController()
})

const loading = reactive({
  generate: 1
})

function appendCanvasAnimated(element: Ref<HTMLCanvasElement | null>, data: Canvas) {
  const canvas = element.value
  if (!canvas) return

  const ctx = canvas.getContext('2d')

  canvas.width = data.width
  canvas.height = data.height

  const background = new Image()
  background.src = data.image

  background.onload = () => {
    const totalFrames = 10
    let currentFrame = 0

    const animate = () => {
      currentFrame++
      const progress = currentFrame / totalFrames

      ctx!.clearRect(0, 0, canvas.width, canvas.height)

      ctx!.globalAlpha = progress
      ctx!.drawImage(background, 0, 0, canvas.width, canvas.height)

      if (currentFrame < totalFrames) {
        requestAnimationFrame(animate)
      } else {
        ctx!.globalAlpha = 1
      }
    }

    requestAnimationFrame(animate)
  }
}

export async function useCanvas(element: Ref<HTMLCanvasElement | null>, initial: CanvasFilter) {
  const values = useForm<CanvasFilter>(initial)

  const rules = shallowRef<RulesSet>()

  async function options() {
    if (controller.options) {
      controller.options.abort('')
    }

    controller.options = new AbortController()
    const { signal } = controller.options

    try {
      const data = await api.get<RulesSet>(`options`, { options: { signal } })
      rules.value = data
    } catch (error) {
      removeToast(loading.generate)
    }
  }

  async function render() {
    showToast({ message: 'Aan het metselen...', type: 'loading', id: loading.generate })

    if (controller.render) {
      controller.render.abort('')
    }

    controller.render = new AbortController()

    const { signal } = controller.render

    try {
      await options()

      const data = await api.post<{ canvas: Canvas; options: CanvasFilter }>(`canvas`, {
        body: values.form.value,
        options: { signal }
      })

      appendCanvasAnimated(element, data.canvas)

      Object.assign(values.form, data.options)
    } catch (error) {
      removeToast(loading.generate)
    } finally {
      removeToast(loading.generate)
    }
  }

  async function download() {
    const { job } = await api.get<{ job: number }>(`export`)

    const checkStatus = async () => {
      const status = await api.get<{
        job: JobResponse
        worker?: WorkerResponse
      }>(`status/${job}`)

      jobStatusHandlerMap[status.job.status](status, checkStatus)
    }

    checkStatus()
  }

  return {
    values,
    rules,
    options,
    render,
    download
  }
}

interface WorkerResponse {
  id: number
  status: number
  message: string
  file: string
}

interface JobResponse {
  id: number
  status: string
}

const toasts = new Map<number, any>()
const pingTimeout = 5000

const updateToastMessage = (id: number, message: string) => {
  if (toasts.has(id)) {
    updateToast(toasts.get(id).id, message)
  } else {
    showToast({ id, message, type: 'download' })
  }

  toasts.set(id, { id, message, type: 'download' })
}

interface JobStatusHandler extends Record<string, (status: { job: JobResponse; worker?: WorkerResponse }, checkStatus: () => void) => void> {}

const jobStatusHandlerMap: JobStatusHandler = {
  pending: ({ job, worker }: { job: JobResponse; worker?: WorkerResponse }, checkStatus: () => void) => {
    if (!worker) updateToastMessage(job.id, 'Aan het starten...')
    setTimeout(checkStatus, pingTimeout)
  },
  processing: ({ job, worker }: { job: JobResponse; worker?: WorkerResponse }, checkStatus: () => void) => {
    if (!worker) {
      updateToastMessage(job.id, 'Aan het verwerken...')
      setTimeout(checkStatus, pingTimeout)
    } else workerStatusHandlerMap[worker!.status](worker, checkStatus)
  },
  completed: ({ job, worker }: { job: JobResponse; worker?: WorkerResponse }, checkStatus: () => void) => {
    if (!worker) removeToast(job.id)
    else workerStatusHandlerMap[worker!.status](worker, checkStatus)
  },
  failed: ({ job }: { job: JobResponse }) => {
    updateToastMessage(job.id, 'Er is iets misgelopen...')
    setTimeout(() => removeToast(job.id), pingTimeout)
  }
}

interface WorkerStatusHandler extends Record<string, (worker: WorkerResponse, checkStatus: () => void) => void> {}

const workerStatusHandlerMap: WorkerStatusHandler = {
  started: (worker: WorkerResponse, checkStatus: () => void) => {
    console.log('worker started')

    updateToastMessage(worker.id, worker.message)
    setTimeout(checkStatus, pingTimeout)
  },
  processing: (worker: WorkerResponse, checkStatus: () => void) => {
    updateToastMessage(worker.id, worker.message)
    setTimeout(checkStatus, pingTimeout)

    console.log('worker processing')
  },
  completed: async (worker: WorkerResponse) => {
    removeToast(worker.id)
    showToast({ id: worker.id, message: 'Images verwerkt', type: 'info' })

    try {
      // Make a fetch request to the server to get the file
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}download/${worker.id}`, {
        method: 'POST',
        body: JSON.stringify({
          file: worker.file
        })
      })

      // Check if the response is OK
      if (!response.ok) {
        throw new Error('File download failed.')
      }

      // Read the response as a Blob
      const blob = await response.blob()

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob)

      // Create a temporary anchor element to initiate the download
      const a = document.createElement('a')
      a.href = url
      a.download = worker.file // The suggested filename
      document.body.appendChild(a)
      a.click()

      // Clean up and remove the temporary anchor
      a.remove()
      window.URL.revokeObjectURL(url)

      console.log('Download started successfully')
    } catch (error) {
      console.error('Error downloading file:', error)
      alert('Failed to download file. Please try again.')
    }
  },
  failed: (worker: WorkerResponse) => {
    removeToast(worker.id)
  }
}
