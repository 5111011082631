import { autoPlacement, autoUpdate, computePosition, type ReferenceElement } from '@floating-ui/dom'
import type { ComponentPublicInstance, DirectiveBinding, Ref } from 'vue'
import { createApp, h, markRaw, ref } from 'vue'

interface UseFloating {
  tooltip: Ref<HTMLElement | null>
  show: () => void
  hide: () => void
  readonly createInstance: (el: HTMLElement, binding: DirectiveBinding) => void
}

interface MapEvent {
  [key: string]: () => void
}

export function useFloating(): UseFloating {
  let element: ReferenceElement
  let directive: DirectiveBinding
  let instanceCreated: boolean = false

  const tooltipInstance = ref<ComponentPublicInstance | null>(null)
  const tooltip = ref<HTMLElement | null>(null)

  function createTooltip() {
    tooltip.value = document.createElement('div')

    tooltip.value.style.opacity = '0'
    tooltip.value.style.position = 'absolute'
    tooltip.value.style.transition = 'opacity 0.3s ease-in-out'

    const tooltipEventMap = markRaw<MapEvent>({
      string: () => {
        if (!tooltip.value) return

        tooltip.value.classList.add('tooltip')
        tooltip.value.innerText = directive.value[0]
        document.body.appendChild(tooltip.value)
      },
      object: () => {
        if (!tooltip.value) return

        document.body.appendChild(tooltip.value)
        const float = createApp({ render: () => h(directive.value[0], directive.value[1] || {}) })
        tooltipInstance.value = float.mount(tooltip.value)
      }
    })

    tooltipEventMap[typeof directive.value[0]]()

    async function update() {
      if (!tooltip.value) return
      const { x, y } = await computePosition(element, tooltip.value, { middleware: [autoPlacement()] })
      Object.assign(tooltip.value.style, { left: `${x}px`, top: `${y}px` })
    }

    autoUpdate(element, tooltip.value, update)
  }

  function show() {
    createTooltip()
    if (!tooltip.value) return
    tooltip.value.style.opacity = '1'
  }

  function hide() {
    if (!tooltip.value) return
    tooltip.value.style.opacity = '0'
    tooltip.value.remove()
  }

  function createInstance(el: HTMLElement, binding: DirectiveBinding) {
    if (!instanceCreated) {
      instanceCreated = true

      element = el
      directive = binding
    } else {
      throw new Error('Instance has already been created')
    }
  }

  return {
    tooltip,
    show,
    hide,
    createInstance
  }
}
