<script setup lang="ts">
import Active from '@/container/components/filters/bricks/components/Active.vue'
import Choice from '@/container/components/filters/bricks/components/Choice.vue'

import type { Form } from '@/services/form'
import type { CanvasFilter, RulesSet } from '=/types'

const show = defineModel<boolean>('show', { required: true })

const values = defineModel<Form<CanvasFilter>>('values', { required: true })

defineProps<{ rules?: RulesSet }>()
</script>

<template>
  <div class="config__content visible">
    <h2 class="config__title">
      NG Lapide natuursteen
      <svg class="icon-close-main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.167 12.167"
        @click.prevent="show = false">
        <path fill="#323232"
          d="m1.28.22 4.803 4.802L10.886.22a.75.75 0 0 1 1.061 1.06L7.144 6.083l4.803 4.803a.75.75 0 0 1-1.06 1.061L6.082 7.144 1.28 11.947a.75.75 0 0 1-1.06-1.06l4.802-4.804L.22 1.28A.75.75 0 0 1 1.28.22Z" />
      </svg>
    </h2>
    <p class="config__text">Selecteer de natuursteen sortering</p>
    <ul class="config__grid active">
      <Active :values />
      <template v-if="values.form.value.bricks.length < 4">
        <li><label @click="show = !show" class="item showmore"></label></li>
      </template>
    </ul>
    <hr />
  </div>
  <ul class="config__grid stones">
    <Choice :values :rules />
  </ul>
</template>
