import { ref } from 'vue'

interface ToastConfig {
  id: number
  message: string
  type: 'success' | 'error' | 'info' | 'loading' | 'download'
  duration?: number
}

const toasts = ref<ToastConfig[]>([])

const showToast = ({ message, type, duration, id }: ToastConfig) => {
  toasts.value.push({ id, message, type, duration })

  if (duration !== undefined && type !== 'loading' && type !== 'download') {
    setTimeout(() => {
      removeToast(id)
    }, duration)
  }

  return id
}

const removeToast = (id: number) => {
  toasts.value = toasts.value.filter((toast) => toast.id !== id)
}

const updateToast = (id: number, newMessage: string, newType?: ToastConfig['type'], newDuration?: number) => {
  // Find the index of the toast with the given id
  const index = toasts.value.findIndex((toast) => toast.id === id)

  if (index !== -1) {
    // Update the existing toast
    toasts.value[index].message = newMessage

    if (newType) {
      toasts.value[index].type = newType
    }

    if (newDuration !== undefined) {
      toasts.value[index].duration = newDuration

      if (newDuration !== undefined && newType !== 'loading' && newType !== 'download') {
        setTimeout(() => {
          removeToast(id)
        }, newDuration)
      }
    }
  } else {
    // If the toast does not exist, create a new one
    showToast({ id, message: newMessage, type: newType ?? 'info', duration: newDuration })
  }
}

export const useToast = () => {
  return {
    toasts,
    showToast,
    removeToast,
    updateToast // Export the new updateToast function
  }
}
