<script setup lang="ts">
import type { Form } from '@/services/form'
import type { CanvasFilter, RulesSet } from '=/types'
import { computed } from 'vue'

const values = defineModel<Form<CanvasFilter>>('values', { required: true })

defineProps<{ rules?: RulesSet }>()

const bricksMap = computed(() => new Map(values.value.form.value.bricks.map((brick) => [brick.name, brick])))

function toggleBrick({ name, example }: RulesSet['bricks'][number]) {
  const bricks = values.value.form.value.bricks
  const brick = bricksMap.value.get(name)

  if (brick) {
    if (bricks.length > 1) {
      const index = bricks.indexOf(brick)
      bricks.splice(index, 1)
    }
  } else {
    if (bricks.length < 4) {
      bricks.push({
        name,
        percentage: 25,
        example
      })
    }
  }
}

const checked = computed(() => (name: string) => bricksMap.value.has(name))
</script>

<template>
  <template v-for="(option, index) in rules?.bricks" :key="index">
    <li>
      <label class="item">
        <input type="checkbox" :name="option.name" :value="option.name" @input="toggleBrick(option)" :checked="checked(option.name)" />
        <img :src="`client/src/assets/images/${option.example}`" class="item__img" />
        <template v-if="checked(option.name)">
          <svg class="icon-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <g fill="none">
              <path fill="#292524" d="M16 1a15 15 0 1 0 0 30 15 15 0 0 0 0-30Z" opacity=".75" />
              <path fill="#292524" d="M16 0a16 16 0 1 1 0 32 16 16 0 0 1 0-32Zm0 2a14 14 0 1 0 0 28 14 14 0 0 0 0-28Z" />
              <path
                fill="#FFF"
                d="M21.07 11.287a1 1 0 1 1 1.416 1.413l-7.91 7.924c-.274.51-.96.801-1.518.339l-.087-.08-.033-.033-3.578-3.578a1 1 0 0 1-.084-1.32l.084-.094a1 1 0 0 1 1.414 0l2.87 2.87 7.426-7.441Z"
              />
            </g>
          </svg>
        </template>
      </label>
      <p class="item__name">{{ option.name }}</p>
    </li>
  </template>
</template>
