<script setup lang="ts"></script>

<template>
  <img src="@/assets/images/example.jpg" class="item__choose" />
</template>

<style scoped>
.example {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
</style>
