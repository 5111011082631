'use strict'

export const METHODS = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
  options: 'OPTIONS'
} as const

export type Method = (typeof METHODS)[keyof typeof METHODS]

interface RequestTarget {
  url: string
  method: Method
}

export interface RequestBody {
  body: any
  params: any
}

type RequestInitWithoutBody = Omit<RequestInit, 'body' | 'params' | 'method'>

export interface Config extends RequestInitWithoutBody, Partial<RequestTarget>, Partial<RequestBody>, Partial<Interceptor> {
  baseURL?: string
  headers?: HeadersInit
  options?: Record<string, any>
}

export type FetchParams = string | string[][] | Record<string, string> | URLSearchParams

interface Interceptor
  extends Record<
    'interceptors',
    {
      request: RequestInterceptor[]
      response: ResponseInterceptor[]
      error: ErrorInterceptor[]
    }
  > {}

interface RequestInterceptor {
  (requestConfig: Partial<Config>): Partial<Config> | Promise<Partial<Config>>
}

interface ResponseInterceptor {
  (response: Response): Response | Promise<Response>
}

interface ErrorInterceptor {
  (error: Response): Response | Promise<Response>
}

interface Instance {
  instanciate: (options: Partial<Config>) => {
    create: <T>(method: Method, uri: string, ...requestConfig: Config[]) => Promise<Awaited<T>>
    interceptors: {
      request: (interceptor: RequestInterceptor, errorInterceptor?: ErrorInterceptor) => void
      response: (interceptor: ResponseInterceptor, errorInterceptor?: ErrorInterceptor) => void
    }
  }
}

const defaultHeaders: HeadersInit = {
  // 'Content-Type': 'application/json',
  // Accept: 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
  // 'Access-Control-Allow-Headers': 'Content-Type'
}

const requestBody: RequestBody = {
  body: undefined,
  params: undefined
}

const requestTarget: RequestTarget = {
  url: '',
  method: 'GET'
}

const interceptor: Interceptor = {
  interceptors: {
    request: [],
    response: [],
    error: []
  }
}

const init: Config = {
  baseURL: '',
  headers: defaultHeaders,
  ...interceptor
}

function clear(a?: unknown[]) {
  do {
    a?.pop()
  } while (a && a.length > 0)
}

export function request(interceptor: RequestInterceptor, error?: ErrorInterceptor) {
  init?.interceptors?.request?.push(interceptor)
  if (error) {
    init?.interceptors?.error?.push(error)
  }
}

export function response(interceptor: ResponseInterceptor, error?: ErrorInterceptor) {
  init?.interceptors?.response?.push(interceptor)
  if (error) {
    init?.interceptors?.error?.push(error)
  }
}

const interceptorsMap: Record<'request' | 'response' | 'error', (value: any) => Promise<void>> = {
  request: async (value: Config) => {
    if (!init.interceptors) return
    for (const interceptor of init.interceptors.request) {
      await interceptor(value)
    }
  },
  response: async (response: Response) => {
    if (!init.interceptors) return
    for (const interceptor of init.interceptors.response) {
      await interceptor(response)
    }
  },
  error: async (error: any) => {
    if (!init.interceptors) return
    for (const interceptor of init.interceptors.error) {
      await interceptor(error)
    }
  }
}

async function doRequest<T>(requestConfig: Config): Promise<T> {
  const config = { ...init, ...requestTarget, ...requestBody, ...requestConfig }

  const { url } = config

  try {
    await interceptorsMap['request'](config)

    const response = await fetch(url, config)

    await interceptorsMap['response'](response)

    if (!response.ok) {
      const errorMessage = response.statusText || 'Unknown error'
      throw new Error(errorMessage)
    }

    if (response.status === 204) {
      return {} as T
    }

    return (await response.json()) as T
  } catch (error) {
    await interceptorsMap['error'](error)
    return Promise.reject(error)
  } finally {
    clear(config.interceptors?.request)
    clear(config.interceptors?.response)
    clear(config.interceptors?.error)
  }
}

function createRequestConfig(method: Method, uri: string, values: Config[]): Config {
  const combinedValues: Config = Object.assign({}, ...values) // Merge all config values
  const url = new URL(uri, init.baseURL)

  if (combinedValues?.params) {
    if (typeof combinedValues.params === 'string') {
      url.search = combinedValues.params
    }

    if (typeof combinedValues.params === 'object') {
      url.search = new URLSearchParams(combinedValues.params).toString()
    }

    if (Array.isArray(combinedValues.params)) {
      url.search = new URLSearchParams(combinedValues.params).toString()
    }

    if (combinedValues?.params instanceof URLSearchParams) {
      url.search = combinedValues.params.toString()
    }
  }

  const newConfig: Config = {
    ...init,
    ...requestTarget,
    ...requestBody,
    ...combinedValues,
    ...combinedValues.options,
    headers: {
      ...defaultHeaders,
      ...combinedValues.headers
    },
    url: url.href,
    method,
    body: typeof combinedValues.body === 'object' ? JSON.stringify(combinedValues.body) : combinedValues.body
  }

  return newConfig
}

export const fetchInstance: Instance = {
  instanciate: (values: Config) => {
    Object.assign(init, values)

    return {
      create: async <T>(method: Method, uri: string, ...values: Config[]): Promise<Awaited<T>> => {
        const config = createRequestConfig(method, uri, values)
        return await doRequest<T>(config)
      },
      interceptors: {
        request,
        response
      }
    }
  }
}
