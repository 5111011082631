<script setup lang="ts">
import { ref, shallowRef, type Component } from 'vue'

import Canvas from '@/container/Canvas.vue'
import Options from '@/container/components/Options.vue'
import Toast from '@/components/toast/Toast.vue'

// TODO: edit example
import example from '@/assets/images/example.jpg'
import Example from '@/container/Example.vue'

export interface DisplayOptions {
  example: string
  component: Component
}

const options = shallowRef<DisplayOptions[]>([
  {
    example: example,
    component: Canvas
  },
  {
    example: example,
    component: Example
  },
  {
    example: example,
    component: Example
  }
])

const active = ref<number>(0)
</script>

<template>
  <KeepAlive>
    <Suspense>
      <Component :is="options[active].component" />
    </Suspense>
  </KeepAlive>
  <Options :options v-model:active="active" />
  <Toast />
</template>
