<script setup lang="ts">
import type { Form } from '@/services/form'
import type { CanvasFilter, RulesSet } from '=/types'
import { shallowRef } from 'vue';

import stack from '@/assets/images/bonds/stack.svg?raw'
import stretcher from '@/assets/images/bonds/stretcher.svg?raw'
import wild from '@/assets/images/bonds/wild.svg?raw'

const values = defineModel<Form<CanvasFilter>>('values', { required: true })

defineProps<{ rules?: RulesSet }>()

const bondsMap = shallowRef<Record<string, any>>({
  'stack': stack,
  'stretcher': stretcher,
  'wild': wild
})
</script>

<template>
  <p class="config__text">Selecteer een verband</p>
  <ul class="config__grid open three meta">
    <template v-for="(bond, index) in rules?.bonds" :key="index">
      <li>
        <label class="item">
          <span v-if="bond.explicit" class="item__label">Aanbevolen</span>
          <input type="radio" :name="bond.name" :value="bond" v-model="values.form.value.bond"
            :checked="values.form.value.bond.name === bond.name" />
          <svg v-html="bondsMap[bond.name]"></svg>
        </label>
      </li>
    </template>
  </ul>
  <template v-if="values.form.value.bond.orientation.options.length > 1">
    <div class="config__form two margin-top orientation">
      <p class="title">Oriëntatie</p>
      <template v-for="(orientation, index) in values.form.value.bond.orientation.options" :key="index">
        <label class="select">
          <input type="radio" name="orientatie" :value="orientation"
            v-model="values.form.value.bond.orientation.default" />
          {{ orientation }}
        </label>
      </template>
    </div>
  </template>
</template>

<style scoped>
.select {
  text-transform: capitalize;
}
</style>
