<script setup lang="ts">
import { ref } from 'vue'

import type { CanvasFilter, RulesSet } from '=/types'
import type { Form } from '@/services/form'

const values = defineModel<Form<CanvasFilter>>('values', { required: true })

defineProps<{ rules?: RulesSet }>()

const dropdown = ref<boolean>(false)

// onMounted(() => document.addEventListener('click', () => dropdown.value = false))
</script>

<template>
  <p class="config__text">Stel hier de specificaties voor de voeg in</p>
  <div class="config__form two">
    <p class="title">Diepte</p>
    <label class="select">
      <input type="radio" name="inset" :value="false" v-model="values.form.value.joint.inset" />
      Vol
    </label>
    <label class="select">
      <input type="radio" name="inset" :value="true" v-model="values.form.value.joint.inset" />
      Verdiept
    </label>
  </div>
  <div class="config__form five">
    <p class="title">Stootvoeg</p>
    <template v-for="(x, index) in rules?.joints.spacing.x" :key="index">
      <label class="select">
        <input type="radio" name="spacing.x" :value="x" v-model="values.form.value.joint.spacing.x" />
        {{ x }}mm
      </label>
    </template>
  </div>
  <div class="config__form five">
    <p class="title">Lintvoeg</p>
    <template v-for="(y, index) in rules?.joints.spacing.y" :key="index">
      <label class="select">
        <input type="radio" name="spacing.y" :value="y" v-model="values.form.value.joint.spacing.y" />
        {{ y }}mm
      </label>
    </template>
  </div>
  <div class="config__form">
    <p class="title">Kleur</p>
    <button class="grout-color" @click="dropdown = !dropdown">
      <div class="color" :style="`background-color: ${values.form.value.joint.type.color}`"></div>
      <template v-if="!values.form.value.joint.type.name"> Selecteer een voegkleur </template>
      <template v-else>
        {{ values.form.value.joint.type.name }}
      </template>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.5 5.5">
        <path
          fill="#78716C"
          d="M.22.22a.75.75 0 0 1 1.06 0l3.47 3.469L8.22.219a.75.75 0 0 1 .976-.072L9.28.22a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z"
        />
      </svg>
    </button>
    <div class="dropdown" :class="{ open: dropdown }">
      <template v-for="(joint, index) in rules?.joints.type" :key="index">
        <label class="dropdown__item" @click.prevent="dropdown = !dropdown">
          <div class="color" :style="`background-color: ${joint.color}`"></div>
          {{ joint.name }}
          <input type="radio" :name="joint.name" :value="joint.name" v-model="values.form.value.joint.type.name" :checked="values.form.value.joint.type.name === joint.name" />
        </label>
      </template>
    </div>
  </div>
</template>
