import { useFloating } from '@/composables/floating'
import type { App, DirectiveBinding } from 'vue'
import { markRaw } from 'vue'

declare global {
  interface EventTarget {
    contains: (value: HTMLElement) => boolean
  }
}

interface MapEvent {
  [key: string]: () => void
}

const DIRECTIVE_NAME = 'floating'

export function createFloating() {
  return {
    install(app: App) {
      app.directive(DIRECTIVE_NAME, {
        mounted(el: HTMLElement, binding: DirectiveBinding) {
          const { tooltip, show, hide, createInstance } = useFloating()

          createInstance(el, binding)

          function click() {
            el.addEventListener('click', show)
            document.addEventListener('click', (event) => {
              if (event.target?.contains(el)) return
              // @ts-expect-error
              if (tooltip.value?.contains(event.target)) return
              hide()
            })
          }

          function hover() {
            el.addEventListener('mouseenter', show)
            // @ts-expect-error
            document.addEventListener('mouseover', (event) => !tooltip.value?.contains(event.target) && hide())
          }

          const showEventMap = markRaw<MapEvent>({ click, hover })
          binding.arg ? showEventMap[binding.arg]() : hover()
        }
      })
    }
  }
}

// export type ModalDirectiveValue<P, E> = [props?: P, emits?: E];

// interface DirectiveBinding<P, E> {
//   instance?: ComponentPublicInstance | null;
//   value: ModalDirectiveValue<P, E>;
//   oldValue?: ModalDirectiveValue<P, E> | null;
//   arg?: Component;
//   modifiers?: Record<string, boolean>;
//   dir?: ObjectDirective<any>;
// }

// export function createModal() {
//   return {
//     install(app: App) {
//       app.directive('modal', <ObjectDirective>{
//         created(el: Element, binding: DirectiveBinding<ModalProps, ModalEmits>, vnode: VNode) {
//           const { showModal, createInstance } = useModal();

//           el.addEventListener('click', () => {
//             if ('__hmrId' in binding.arg!) createInstance(binding, binding.arg!.__hmrId || vnode.key);
//             showModal();
//           });
//         },
//         unmounted(el: Element) {
//           const { closeModal } = useModal();
//           el.removeEventListener('click', closeModal);
//         },
//       });
//     },
//   };
// }
