<template>
  <div class="toast-container">
    <div v-for="toast in toasts" :key="toast.id" class="toast" :class="toast.type">
      <div v-if="toast.type === 'loading'" class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
        <span class="loading-text">{{ toast.message }}</span>
      </div>
      <div v-else-if="toast.type === 'download'" class="download">
        <span class="spinner"></span>
        <span class="loading-text">{{ toast.message }}</span>
      </div>
      <span v-else>{{ toast.message }}</span>
      <a class="close" v-if="toast.type !== 'loading' && toast.type !== 'download'" @click="removeToast(toast.id)">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.5 16.5">
          <path fill="rgba(255,255, 255,0.6)" d="M8.25 0a8.25 8.25 0 0 1 8.25 8.25 8.25 8.25 0 0 1-8.25 8.25A8.25 8.25 0 0 1 0 8.25 8.25 8.25 0 0 1 8.25 0Z" opacity=".75" />
          <path
            fill="rgba(255,255, 255,0.6)"
            d="M6.422 5.361 8.25 7.19l1.828-1.828a.75.75 0 0 1 1.06 1.06L9.312 8.25l1.828 1.828a.75.75 0 0 1-1.061 1.06L8.25 9.312 6.422 11.14a.75.75 0 0 1-1.06-1.061L7.188 8.25 5.36 6.422a.75.75 0 0 1 1.06-1.06Z"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from '.'

const { toasts, removeToast } = useToast()
</script>

<style scoped lang="scss">
.toast-container {
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;

  .toast {
    display: flex;
    padding: 16px 24px;
    border-radius: 8px;
    max-height: 46px;
    color: #fefefe;
    background-color: #f3f3f3;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease;
    align-items: center;
    justify-content: space-between;

    &.success {
      background-color: rgba(#4caf50, 0.8);
    }

    &.error {
      background-color: rgba(#f44336, 0.8);
    }

    &.info {
      background-color: rgba(#2196f3, 0.8);
    }

    &.download {
      background-color: rgba(#ae00ff, 0.8);
    }

    &.loading {
      background-color: rgba(#ff9800, 0.8);
    }

    .download {
      display: flex;
      align-items: center;

      .spinner {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 10px;
        display: inline-block;
        border-top: 3px solid white;
        border-right: 3px solid transparent;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .spinner {
      display: flex;
      align-items: center;

      .double-bounce1,
      .double-bounce2 {
        width: 18px;
        height: 18px;
        background-color: white;
        border-radius: 100%;
        opacity: 0.6;
        position: relative;
        animation: bounce 2s infinite ease-in-out;
        margin-right: 10px;
      }

      .double-bounce2 {
        animation-delay: -1s;
      }

      @keyframes bounce {
        0%,
        100% {
          transform: scale(0);
        }

        50% {
          transform: scale(1);
        }
      }
    }

    .close {
      color: black;
      cursor: pointer;
      height: 22px;
      width: 22px;
    }
  }
}
</style>
