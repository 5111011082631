<script setup lang="ts">
import type { Form } from '@/services/form'
import type { CanvasFilter, RulesSet } from '=/types'

const values = defineModel<Form<CanvasFilter>>('values', { required: true })

defineProps<{ rules?: RulesSet }>()
</script>

<template>
  <p class="config__text">Stel hier de afmetingen van de steen in</p>
  <div class="config__form two">
    <p class="title">Hoogte</p>
    <template v-for="(height, index) in rules?.sizes.heights" :key="index">
      <label class="select">
        <input type="radio" name="hoogte" :value="height" v-model="values.form.value.sizes.height" />
        {{ height }}mm
      </label>
    </template>
  </div>
  <div v-if="!values.form.value.bond.fixed_widths" class="config__form three">
    <p class="title">Lengte</p>
    <template v-for="(width, index) in rules?.sizes.widths" :key="index">
      <label class="select">
        <input
          type="radio"
          name="lengte"
          :value="width"
          v-model="values.form.value.sizes.width"
          :checked="values.form.value.sizes.width === width"
          :disabled="values.form.value.bond.fixed_widths"
        />
        {{ width }}mm
      </label>
    </template>
  </div>
</template>
