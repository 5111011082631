import { useToast } from '@/components/toast'
import { fetchInstance, type Config } from './fetch'

const baseURL = import.meta.env.VITE_BACKEND_URL

const { showToast } = useToast()

const instance = fetchInstance.instanciate({ baseURL })

const errorCodeMap: { [key: number]: (error: Response) => void } = {
  400: () => 'Bad Request: The server cannot process the request due to a client error.',
  401: () => 'Unauthorized: Authentication is required to access this resource.',
  403: () => 'Forbidden: The server understood the request, but refuses to authorize it.',
  404: () => 'Not Found: The requested resource could not be found.'
}

function responseErrors(error: Response) {
  if (error && errorCodeMap[error.status]) {
    errorCodeMap[error.status](error)
  } else {
    console.error('Response error:', error)
  }

  return Promise.reject(error)
}

instance.interceptors.request(
  (config) => config,
  (error) => {
    showToast({ id: error.status, message: 'Request error', type: 'error' })
    return responseErrors(error)
  }
)

instance.interceptors.response(
  (response) => response,
  (error) => {
    if (error.statusText === 'canceled') return responseErrors(error)

    showToast({
      id: error.status,
      message: error.statusText || 'Something went wrong!',
      type: 'error',
      duration: 3000
    })

    return responseErrors(error)
  }
)

export const api = {
  get: <T>(url: string, ...config: Config[]): Promise<T> => instance.create<T>('GET', url, ...config),
  post: <T>(url: string, ...config: Config[]): Promise<T> => instance.create<T>('POST', url, ...config),
  patch: <T>(url: string, ...config: Config[]): Promise<T> => instance.create<T>('PATCH', url, ...config),
  put: <T>(url: string, ...config: Config[]): Promise<T> => instance.create<T>('PUT', url, ...config),
  delete: <T>(url: string, ...config: Config[]): Promise<T> => instance.create<T>('DELETE', url, ...config)
}
