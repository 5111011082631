import { createApp } from 'vue'
import { createFloating } from './directive/floating'

import App from './App.vue'

import './assets/scss/index.scss'

const app = createApp(App)

const float = createFloating()

app.use(float)

app.mount('#app')
