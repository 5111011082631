<script setup lang="ts">
import { ref, type Component } from 'vue'

export interface AccordionObject {
  title: string
  component: Component
  info?: string // change to string or modal check whats needed
}

const accordion = defineModel<AccordionObject[]>('accordion', { required: true })

const show = ref<number>(-1)
</script>

<template>
  <template v-for="({ title, component, info }, index) in accordion" :key="index">
    <div class="config__content meta" :class="show === index ? 'open' : 'closed'">
      <h2 class="config__title" @click.prevent="show === index ? (show = -1) : (show = index)">
        {{ title }}
        <template v-if="info">
          <svg v-floating:hover="['Dit is een text tooltip']" class="icon-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
            <path fill="#A8A29E" d="M7.5 14.25a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z" />
            <path fill="#A8A29E" d="M7.5 0a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15Zm0 1.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12Z" />
            <path fill="#FFF" d="M8.482 9.375a.75.75 0 0 1 0 1.5H6.75a.75.75 0 1 1 0-1.5h1.732Z" />
            <path fill="#FFF" d="M7.62 6.188a.75.75 0 0 1 .75.75v3.187a.75.75 0 1 1-1.5 0l-.001-2.438h-.112a.75.75 0 0 1-.743-.648l-.007-.101a.75.75 0 0 1 .75-.75h.862Z" />
            <path fill="#FFF" d="M7.388 3.748c.241 0 .456.114.593.292l.02.032.032.02a.75.75 0 0 1 .285.492l.007.102a.937.937 0 1 1-.937-.938Z" />
            <path fill="#FFF" d="M7.388 3.748c.517 0 .937.42.937.938a.75.75 0 0 1-1.344.458l-.02-.032-.032-.02a.75.75 0 0 1-.285-.492l-.006-.102a.75.75 0 0 1 .75-.75Z" />
          </svg>
        </template>
        <svg class="icon-close" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <path stroke="#292524" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 12h14" />
          <path stroke="#292524" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 19V5" />
        </svg>
      </h2>
      <component :is="component" v-bind="{ ...$attrs }" />
      <hr />
    </div>
  </template>
</template>
